import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, orderBy, query, serverTimestamp, updateDoc } from "firebase/firestore";
import { firestore } from "../configs/firebase";
import { Course } from "models/Course";
import { environment } from "configs/environment";
import { FileStorageRepository } from "./FileStorageRepository";

export class CourseRepository {
  /**
   * Retrieves all courses from the Firestore collection.
   * @returns {Promise<Array<Course>>} A Promise that resolves to an array of User objects.
   */
  async findAllCourse() {
    const response = await getDocs(query(collection(firestore, environment.COLLECTION.COURSES), orderBy("createdDate", "desc")))
    const parseResponse = response.docs.map(document => new Course({ id: document.id, ...document.data() }));
    console.log("findAllCourse", parseResponse);
    return parseResponse;
  }

  /**
   * Retrieve a course from the Firestore collection.
   * @param {string} courseId - document id from firebase
   * @returns {Promise<Array<Course>>} A Promise that resolves to an array of User objects.
   */
  async findCourseById(courseId) {
    const document = await getDoc(doc(firestore, environment.COLLECTION.COURSES, courseId));
    if (document.exists()) {
      const parseCourse = new Course({ id: document.id, ...document.data() });
      console.log("findCourseById", parseCourse);
      return parseCourse;
    }
    return null;
  }

  /**
   * Saves a course to the Firestore collection.
   * If the course object has an ID, the corresponding document will be updated with the new data.
   * Otherwise, a new document will be created.
   * @param {Course} course The course object to save.
   * @returns {Promise<Course>} A Promise that resolves to the updated or newly created User object.
   */
  async save(course) {
    const referenceCollection = collection(firestore, environment.COLLECTION.COURSES);
    const updateCourse = { ...course }
    if (updateCourse.id) {
      const accountId = updateCourse.id;
      delete updateCourse.id;
      updateCourse.updatedDate = serverTimestamp();
      updateCourse.videos = updateCourse.videos?.map(video => ({...video}));
      const documentReference = doc(firestore, environment.COLLECTION.COURSES, accountId)
      await updateDoc(documentReference, updateCourse)
      return new Course({ id: accountId, ...updateCourse, updatedDate: new Date() });
    } else {
      delete updateCourse.id;
      updateCourse.createdDate = serverTimestamp();
      updateCourse.videos = updateCourse.videos?.map(video => ({...video}));
      const response = await addDoc(referenceCollection, updateCourse)
      return new Course({ ...course, id: response.id, createdDate: new Date() })
    }
  }

  /**
   * delete the course from firestore collection and all files in the storage
   * 
   * @param {string} courseId 
   * @returns {Promise<void>}
   */
  async delete(courseId) {
    const fileStorageRepository = new FileStorageRepository();
    const referenceCollection = doc(firestore, environment.COLLECTION.COURSES, courseId);
    const querySnapshot = await getDoc(referenceCollection);
    if (querySnapshot.exists()) {
      const document = querySnapshot.data();
      fileStorageRepository.delete(document.videoReference?.id);
      fileStorageRepository.delete(document.tumbnailReference?.id);
      return await deleteDoc(referenceCollection);
    }
    return null;
  }
}