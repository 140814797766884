import { CircularProgress, Dialog, DialogContent } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftProgress from "components/SoftProgress";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";

export function ProgressBarModal(props) {
  return (
    <Dialog open={props.open}>
      <SoftBox mx="16px" mt="16px">
        <SoftTypography variant="h5">Processing...</SoftTypography>
        <SoftTypography variant="button" fontWeight="regular" color="text">
          Please wait while your files and data are being uploaded to the server.
        </SoftTypography>
      </SoftBox>
      <DialogContent>
        <SoftBox display={props.percent == 100 ? "none" : "flex"} flex="1" flexDirection="column" py={3}>
          <SoftBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
            <SoftTypography variant="button" fontWeight="medium" color="text" textTransform="capitalize">
              Uploading file:
            </SoftTypography>
            <SoftTypography variant="button" fontWeight="medium" color="text" textTransform="capitalize" >
              {props.percent || 0}%
            </SoftTypography>
          </SoftBox>
          <SoftProgress variant="gradient" value={props.percent || 0} />
        </SoftBox>
        <SoftBox display={props.percent == 100 ? "flex" : "none"} flex="1" py={3} justifyContent="center">
          <CircularProgress color="inherit" />
        </SoftBox>
      </DialogContent>
    </Dialog>
  )
}

ProgressBarModal.propTypes = {
  open: PropTypes.bool.isRequired,
  percent: PropTypes.object.isRequired,
};
