import { useContext, useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftEditor from "components/SoftEditor";
import SoftSelect from "components/SoftSelect";
import FormField from "components/layouts/ecommerce/products/edit-product/components/FormField";
import PropTypes from "prop-types";
import { COURSE_SIZE, COURSE_STATUS, SKILL_LEVEL } from "views/courses/create/fragments/ProductInfo";
import { CURRENCY, TAGS } from "views/courses/create/fragments/PricingInfo";
import SoftButton from "components/SoftButton";
import { Context } from "contexts/contextState";
import { errorAlert, successAlert } from "views/components/Alerts";
import { handleFormData, handleSelectFormData } from "services/HandleService";

export function EditProductInfo(props) {
  const { context } = useContext(Context);
  const textareaEditor = useRef();

  async function updateProductInfos() {
    try {
      console.log(textareaEditor.current, props.course.description);
      const response = await context.courseRepository.save({ ...props.course, description: textareaEditor.current || props.course.description });
      console.log("updateProductInfos", response);
      successAlert("Success!", "The edits made to your course have been successfully updated in the database.");
    } catch (error) {
      console.log("updateProductInfos", error);
      errorAlert("Error!", "An error occurred while updating the database with the edits made to your course. Please try again later.");
    }
  }

  return (
    <Card sx={{ overflow: "visible" }}>
      <SoftBox p={3}>
        <SoftTypography variant="h5">Information</SoftTypography>
        <SoftBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField type="text" label="title" name="title" value={props.course.title} onChange={(event) => handleFormData(event, props.setCourse)} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SoftBox mb={3}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography component="label" variant="caption" fontWeight="bold" textTransform="capitalize">
                    Status
                  </SoftTypography>
                </SoftBox>
                <SoftSelect value={props.course.active != void 0 ? COURSE_STATUS[props.course.active ? 0 : 1] : COURSE_STATUS[1]} options={COURSE_STATUS} onChange={(obj) => handleSelectFormData("active", obj.value, props.setCourse)} />
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
        <SoftBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <SoftBox mb={3}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography component="label" variant="caption" fontWeight="bold" textTransform="capitalize">
                    Skill Level
                  </SoftTypography>
                </SoftBox>
                <SoftSelect value={props.course.courseLevel || SKILL_LEVEL[0]} options={SKILL_LEVEL} onChange={(obj) => handleSelectFormData("courseLevel", obj, props.setCourse)} />
              </SoftBox>
            </Grid>
            <Grid item xs={12} sm={6}>
              <SoftBox mb={3}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography component="label" variant="caption" fontWeight="bold" textTransform="capitalize">
                    Size
                  </SoftTypography>
                </SoftBox>
                <SoftSelect value={props.course.courseSize || COURSE_SIZE[0]} options={COURSE_SIZE} onChange={(obj) => handleSelectFormData("courseSize", obj, props.setCourse)} />
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
        <SoftBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Description&nbsp;&nbsp;
                  <SoftTypography variant="caption" fontWeight="regular" color="text">
                    (optional)
                  </SoftTypography>
                </SoftTypography>
              </SoftBox>
              {/* there is a typing bug on this component so it was implemented like that */}
              <SoftEditor
                value={props.course.description || ""}
                onChange={(value) => { textareaEditor.current = value }}
                onBlur={() => {
                  handleSelectFormData("description", textareaEditor.current, props.setCourse)
                }}
              />
            </Grid>
          </Grid>
        </SoftBox>
        <SoftBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Course Tags
                </SoftTypography>
              </SoftBox>
              <SoftSelect value={props.course.tags || []} options={TAGS} size="large" isMulti onChange={(obj) => handleSelectFormData("tags", obj, props.setCourse)} />
            </Grid>
          </Grid>
        </SoftBox>
        <SoftBox mt={3}>
          <SoftTypography variant="h5">Pricing</SoftTypography>
          <SoftBox mt={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormField type="number" label="price" name="price" value={props.course?.price || ""} placeholder="99.00" onChange={(event) => handleFormData(event, props.setCourse)} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography component="label" variant="caption" fontWeight="bold" textTransform="capitalize" >
                    Currency
                  </SoftTypography>
                </SoftBox>
                <SoftSelect value={props.course?.currency || ""} options={CURRENCY} onChange={(obj) => handleSelectFormData("currency", obj, props.setCourse)} />
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
        <SoftBox mt={5} display="flex" justifyContent="end">
          <SoftButton variant="gradient" color="info" size="small"
            onClick={updateProductInfos}
            disabled={!(context.permission?.root || context.permission?.course?.update)}
          >
            Save
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

EditProductInfo.propTypes = {
  course: PropTypes.object.isRequired,
  setCourse: PropTypes.func.isRequired
}
