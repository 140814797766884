import { useState, useEffect, useContext } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import Document from "components/examples/Icons/Document";
import Settings from "components/examples/Icons/Settings";
import breakpoints from "assets/theme/base/breakpoints";
import { AssignmentInd } from "@mui/icons-material";
import PropTypes from "prop-types";
import { Context } from "contexts/contextState";

export function CustomerHeader(props) {
  const { context } = useContext(Context);
  const [imageProfile, setImageProfile] = useState("");
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  async function handlePageLoader() {
    if (props.customer.imageProfileReference) {
      const fileResponse = await context.fileStorageRepository.findFileStorageById(props.customer.imageProfileReference?.id);
      setImageProfile(fileResponse?.fileUrl || "");
    }
  }

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm ? setTabsOrientation("vertical") : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();

    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  useEffect(() => {
    if (props.onChange) {
      props.onChange(tabValue);
    }
  }, [tabValue]);

  useEffect(() => {
    if (!imageProfile) {
      handlePageLoader();
    }
  }, [props.customer]);

  return (
    <SoftBox>
      <Card sx={{ backdropFilter: `saturate(200%) blur(30px)`, backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8), boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow, mx: 3, py: 2, px: 2 }}>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <SoftAvatar src={imageProfile} alt="profile-image" variant="rounded" size="xl" shadow="sm" />
          </Grid>
          <Grid item>
            <SoftBox height="100%" mt={0.5} lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                {((props.customer?.firstName || "") + " " + (props.customer?.lastName || "")).trim()}
              </SoftTypography>
              <SoftTypography variant="button" color="text" fontWeight="medium">
                {props.customer?.email || ""}
              </SoftTypography>
            </SoftBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <Tabs orientation={tabsOrientation} value={tabValue} onChange={(event, newValue) => setTabValue(newValue)} sx={{ background: "transparent" }}>
                <Tab label="Profile" icon={<AssignmentInd fontSize="small" />} />
                <Tab label="Permission" icon={<Document />} />
                <Tab label="Settings" icon={<Settings />} />
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
      </Card>
    </SoftBox>
  );
}

CustomerHeader.propTypes = {
  customer: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
}
