export class User {
  constructor(entity) {
    this.id = entity?.id || null;
    this.active = entity?.active || false;
    this.firstName = entity?.firstName || null;
    this.lastName = entity?.lastName || null;
    this.gender = entity?.gender || null;
    this.phone = entity?.phone || null;
    this.email = entity?.email || null;
    /** @type {ACCOUNT_PERMISSION} */
    this.permission = entity?.permission || ACCOUNT_PERMISSION;
    this.purchases = entity?.purchases || []
    this.imageProfileReference = entity?.imageProfileReference || null;
    this.birthDate = entity?.birthDate instanceof Date ? entity?.birthDate : entity?.birthDate?.toDate() || null;
    this.createdDate = entity?.createdDate instanceof Date ? entity?.createdDate : entity?.createdDate?.toDate() || null;
    this.updatedDate = entity?.updatedDate instanceof Date ? entity?.updatedDate : entity?.updatedDate?.toDate() || null;
  }
}

export const ACCOUNT_PERMISSION = {
  root: false,
  admin: false,
  customer: {
    view: false,
    update: false,
    delete: false
  },
  course: {
    view: false,
    update: false,
    delete: false
  }
}