import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useContext, useState } from "react";
import { DeleteCustomerModal } from "../DeleteCustomerModal";
import { Context } from "contexts/contextState";

export function CustomerActionCell(props) {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  return (
    <SoftBox display="flex" alignItems="center">
      <DeleteCustomerModal open={openDeleteModal} customer={props.customer} onClose={() => setOpenDeleteModal(false)} />
      <SoftBox mx={2}>
        <SoftTypography component={Link} to={"/admin/customers/all/edit/" + props.customer.id} variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }}>
          <Tooltip title="Edit Customer" placement="top">
            <Icon>edit</Icon>
          </Tooltip>
        </SoftTypography>
      </SoftBox>
      <SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} onClick={() => setOpenDeleteModal(true)}>
        <Tooltip title="Delete Customer" placement="left">
          <Icon>delete</Icon>
        </Tooltip>
      </SoftTypography>
    </SoftBox>
  );
}

CustomerActionCell.propTypes = {
  customer: PropTypes.any.isRequired,
};