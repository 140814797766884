import { Box, Dialog, DialogActions, DialogContent } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { environment } from "configs/environment";
import { Context } from "contexts/contextState";
import { doc } from "firebase/firestore";
import { Course } from "models/Course";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { firestore } from "services/firebase/configs/firebase";
import { successAlert } from "views/components/Alerts";
import { ProgressBarModal } from "views/components/ProgressBarModal";
import { SoftDropzone } from "views/components/SoftDropzone";
import { handleSelectFormData } from "services/HandleService";

export function UploadThumbnailModal(props) {
  const { context } = useContext(Context);
  const [processing, setProcessing] = useState(false);
  const [percent, setPercent] = useState(0);
  const [thumbnail, setThumbnail] = useState({});

  async function handleThumbnailUpdate() {
    setProcessing(true);
    try {
      if (thumbnail.file) {
        const course = new Course(props.course);
        const responseThumbnailStorage = await context.fileStorageService.imageUpload(thumbnail.file, (event) => {
          setPercent(Math.floor(event.loaded / event.total * 100));
        });
        const lastCourseThumbnailId = course.thumbnailReference?.id;
        if (lastCourseThumbnailId) {
          context.fileStorageRepository.delete(lastCourseThumbnailId);
        }
        course.thumbnailReference = doc(firestore, environment.COLLECTION.FILE_STORAGES, responseThumbnailStorage.id);
        const courseResponse = await context.courseRepository.save(course);
        console.log("handleThumbnailUpdate", courseResponse);
        successAlert("Success!", "The thumbnail has been successfully updated.").then(() => {
          window.location.reload();
        });
        setThumbnail({});
        props.onClose();
      }
    } catch (error) {
      console.log("handleThumbnailUpdate", error);
    } finally {
      setProcessing(false);
    }
  }

  useEffect(() => {
    console.log(thumbnail);
  }, [thumbnail]);

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <ProgressBarModal open={processing} percent={percent} onClose={props.onClose} />
      <SoftBox mx="16px" mt="16px">
        <SoftTypography variant="h5">Thumbnail</SoftTypography>
        <SoftTypography variant="button" fontWeight="regular" color="text">
          If you confirm the update, the last image will be deleted.
        </SoftTypography>
      </SoftBox>
      <DialogContent>
        <SoftBox display="flex" flex="1" flexDirection="column" mt={1} minWidth="480px">
          <SoftBox mt={1}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography component="label" variant="caption" fontWeight="bold" textTransform="capitalize">
                Select Image
              </SoftTypography>
            </SoftBox>
            <SoftDropzone file={thumbnail.file} options={{
              addRemoveLinks: true,
              maxFiles: 1, // Allow only one file
              acceptedFiles: "image/*", // Accept only video files
              dictDefaultMessage: "Drop a image file here or click to upload",
              init: function () {
                this.on("addedfile", (file) => {
                  if (this.files.length > 1) {
                    this.removeFile(this.files[0]);
                  }
                  handleSelectFormData("file", file, setThumbnail);
                });
                this.on("removedfile", (file) => {
                  handleSelectFormData("file", null, setThumbnail);
                });
              },
            }} />
          </SoftBox>
        </SoftBox>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="space-between" flex="1">
          <SoftButton variant="text" color="info" onClick={props.onClose} disabled={processing}>Cancel</SoftButton>
          <SoftButton variant="text" color="info"
            onClick={handleThumbnailUpdate}
            disabled={!(context.permission?.root || context.permission?.course?.update)}
          >
            Confirm
          </SoftButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

UploadThumbnailModal.propTypes = {
  course: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}