/**
=========================================================
* Cakes By Sim Admin - v4.0.0
=========================================================

* Product Page: https://codejays.com//product/soft-ui-dashboard-pro-react
* Copyright 2022 Code Jays (https://codejays.com/)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Cakes By Sim Admin helper functions
import pxToRem from "assets/theme/functions/pxToRem";

// Cakes By Sim Admin base styles
import colors from "assets/theme/base/colors";
import boxShadows from "assets/theme/base/boxShadows";
import borders from "assets/theme/base/borders";

const { transparent } = colors;
const { lg } = boxShadows;
const { borderRadius } = borders;

const popover = {
  styleOverrides: {
    paper: {
      backgroundColor: transparent.main,
      boxShadow: lg,
      padding: pxToRem(8),
      borderRadius: borderRadius.lg,
    },
  },
};

export default popover;
