import { Box, Dialog, DialogActions, DialogContent } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftSelect from "components/SoftSelect";
import SoftTypography from "components/SoftTypography";
import FormField from "components/layouts/applications/wizard/components/FormField";
import { Context } from "contexts/contextState";
import { Course } from "models/Course";
import { VIDEO_ENUM, VIDEO_STATUS, Video } from "models/Video";
import PropTypes from "prop-types";
import { useContext, useState } from "react";
import { errorAlert, successAlert } from "views/components/Alerts";
import { SoftDropzone } from "views/components/SoftDropzone";
import { handleFormData, handleSelectFormData } from "services/HandleService";

export function EditVideoModal(props) {
  const { context } = useContext(Context);
  const [formVideo, setFormVideo] = useState(new Video(props.video));
  const [processing, setProcessing] = useState(false);

  async function handleSaveVideoLesson() {
    setProcessing(true);
    try {
      console.log(formVideo);
      const course = new Course(props.course);
      formVideo.updatedDate = new Date();
      course.videos = course.videos.map(video => video.id == formVideo.id ? formVideo : video);
      await context.courseRepository.save(course);
      successAlert("Success!", "The edits made to your video have been successfully updated in the database.").then(() => {
        window.location.reload();
      });
      props.onClose();
    } catch (error) {
      console.log(error);
      errorAlert("Error!", "Failed to update a video in our database. Please check your connection and try again. If the issue persists, please contact our support team for assistance.");
    } finally {
      setProcessing(false);
    }
  }

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <SoftBox mx="16px" mt="16px">
        <SoftTypography variant="h5">Video Information</SoftTypography>
        <SoftTypography variant="button" fontWeight="regular" color="text">
          Edit all information about {props.video.title}
        </SoftTypography>
      </SoftBox>
      <DialogContent>
        <SoftBox display="flex" flex="1" flexDirection="column" mt={1} minWidth="480px">
          <FormField type="text" label="title" name="title" value={formVideo.title || ""} placeholder="eg. Oreo Overloaded" onChange={(event) => handleFormData(event, setFormVideo)} />
          <SoftBox mt={1}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography component="label" variant="caption" fontWeight="bold" textTransform="capitalize">
                Status
              </SoftTypography>
            </SoftBox>
            <SoftSelect value={formVideo.active != void 0 ? VIDEO_STATUS[formVideo.active ? 0 : 1] : VIDEO_STATUS[1]} options={VIDEO_STATUS} onChange={(obj) => handleSelectFormData("active", obj.value, setFormVideo)} />
          </SoftBox>
          <SoftBox mt={1}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography component="label" variant="caption" fontWeight="bold">Description</SoftTypography>
              <SoftTypography variant="caption" fontWeight="regular" color="text">(optional)</SoftTypography>
            </SoftBox>
            <FormField type="text" name="description" value={formVideo.description || ""} onChange={(event) => handleFormData(event, setFormVideo)} />
          </SoftBox>
          <SoftBox mt={1} display={formVideo.privacy == VIDEO_ENUM.PRIVATE ? "none" : "block"}>
            <FormField type="text" value={formVideo.youtubeLink || ""} name="youtubeLink" label="youtube link" placeholder="https://..." onChange={(event) => handleFormData(event, setFormVideo)} />
          </SoftBox>
        </SoftBox>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="space-between" flex="1">
          <SoftButton variant="text" color="info" onClick={props.onClose} disabled={processing}>Cancel</SoftButton>
          <SoftButton variant="text" color="info"
            onClick={handleSaveVideoLesson}
            disabled={!(context.permission?.root || context.permission?.course?.update)}
          >
            Confirm
          </SoftButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

EditVideoModal.propTypes = {
  course: PropTypes.object.isRequired,
  video: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}