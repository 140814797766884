import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { Context } from "contexts/contextState";
import { Course } from "models/Course";
import PropTypes from "prop-types";
import { useContext, useState } from "react";
import { errorAlert, successAlert } from "views/components/Alerts";

export function DeleteThumbnailModal(props) {
  const { context } = useContext(Context);
  const [processing, setProcessing] = useState(false);

  async function handleDeleteThumbnail() {
    setProcessing(true);
    try {
      const course = new Course(props.course);
      const lastCourseThumbnailId = course.thumbnailReference?.id;
      if (lastCourseThumbnailId) {
        await context.fileStorageRepository.delete(lastCourseThumbnailId);
      }
      course.thumbnailReference = null;
      const courseResponse = await context.courseRepository.save(course);
      console.log("handleDeleteThumbnail", courseResponse);
      successAlert("Success!", "The thumbnail has been deleted.").then(() => {
        window.location.reload();
      });
      props.onClose();
    } catch (error) {
      console.log("handleDeleteThumbnail", error);
      errorAlert("Error!", "Failed to delete a thumbnail in our database. Please check your connection and try again. If the issue persists, please contact our support team for assistance.");
    } finally {
      setProcessing(false);
    }
  }

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>
        Are you certain you want to delete this thumbnail?
      </DialogTitle>
      <DialogContent>
        <SoftTypography variant="body2">
          If you delete this thumbnail, it cannot be recovered. <br />Are you sure you want to proceed?
        </SoftTypography>
        <br />
        <SoftTypography variant="body2">
          Click the <b>Delete</b> button to confirm or <b>Cancel</b> to go back.
        </SoftTypography>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="space-between" flex="1">
          <SoftButton variant="text" color="error"
            onClick={handleDeleteThumbnail}
            disabled={!(context.permission?.root || context.permission?.course?.delete)}
          >
            Delete
          </SoftButton>
          <SoftButton variant="text" color="info" onClick={props.onClose} disabled={processing}>Cancel</SoftButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

DeleteThumbnailModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  video: PropTypes.object.isRequired,
  course: PropTypes.object.isRequired,
};