import { collection, getDocs, where, documentId, query, serverTimestamp, doc, updateDoc, deleteDoc, orderBy } from "firebase/firestore"
import { User } from "models/User";
import { firestore } from "../configs/firebase";
import { environment } from "configs/environment";
import { FileStorageRepository } from "./FileStorageRepository";

/**
 * Represents a repository for managing user data in a Firestore database.
 */
export class UserRepository {
  /**
   * Retrieves all users from the Firestore collection.
   * @returns {Promise<Array<User>>} A Promise that resolves to an array of User objects.
   */
  async findAllUser() {
    const response = await getDocs(query(collection(firestore, environment.COLLECTION.ACCOUNTS), orderBy("createdDate", "desc")));
    const parseResponse = response.docs.map(document => new User({ id: document.id, ...document.data() }));
    console.log("findAllUser", parseResponse);
    return parseResponse;
  }

  /**
   * Retrieves a user by their ID from the Firestore collection.
   * @param {string} userId The ID of the user to retrieve.
   * @returns {Promise<User|null>} A Promise that resolves to a User object if a user with the specified ID is found, or null if not.
   */
  async findUserById(userId) {
    const referenceCollection = collection(firestore, environment.COLLECTION.ACCOUNTS);
    const response = await getDocs(query(referenceCollection, where(documentId(), "==", userId)));
    const parseResponse = response.docs.map(document => new User({ id: document.id, ...document.data() }));
    const result = !!parseResponse.length ? parseResponse[0] : null;
    console.log("findUserById", result);
    return result;
  }

  /**
   * Saves a user to the Firestore collection.
   * If the user object has an ID, the corresponding document will be updated with the new data.
   * Otherwise, a new document will be created.
   * @param {User} user The user object to save.
   * @returns {Promise<User>} A Promise that resolves to the updated or newly created User object.
   */
  async saveUser(user) {
    const referenceCollection = collection(firestore, environment.COLLECTION.ACCOUNTS);
    const account = { ...user }
    if (account.id) {
      const accountId = account.id;
      delete account.id;
      account.updatedDate = serverTimestamp();
      const documentReference = doc(firestore, environment.COLLECTION.ACCOUNTS, accountId)
      await updateDoc(documentReference, account)
      return new User({ id: accountId, ...account, updatedDate: new Date() });
    } else {
      account.createdDate = serverTimestamp();
      const response = await addDoc(referenceCollection, account)
      return new User({ ...user, id: response.id, createdDate: new Date() })
    }
  }

  /**
   * delete a user from Firestore
   * 
   * @param {string} accountId - firebase document id
   * @returns {Promise<void>}
   */
  async delete(accountId) {
    if (accountId) {
      const account = await this.findUserById(accountId);
      if (account) {
        if (account.imageProfileReference) {
          const fileStorageRepository = new FileStorageRepository();
          fileStorageRepository.delete(account.imageProfileReference.id);
        }
        return await deleteDoc(doc(firestore, environment.COLLECTION.ACCOUNTS, accountId));
      }
    }
    return null;
  }
}
