import { Video } from "./Video";

export class Course {
  constructor(entity) {
    this.id = entity?.id || null;
    this.title = entity?.title || null;
    this.active = entity?.active || false;
    this.description = entity?.description || null;
    this.courseLevel = entity?.courseLevel || null;
    this.courseSize = entity?.courseSize || null;
    this.youtubeLink = entity?.youtubeLink || null;
    this.thumbnailReference = entity?.thumbnailReference || null;
    this.videos = entity?.videos?.map(video => new Video(video)) || [];
    this.price = entity?.price || 0;
    this.currency = entity?.currency || null;
    this.tags = entity?.tags || null;
    this.createdDate = entity?.createdDate instanceof Date ? entity?.createdDate : entity?.createdDate?.toDate() || null;
    this.updatedDate = entity?.updatedDate instanceof Date ? entity?.updatedDate : entity?.updatedDate?.toDate() || null;
  }
}