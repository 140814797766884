import { FormatterService } from "services/FormatterService";
import { ActiveDisabled } from "views/components/ActiveDisabled";
import { CourseProductCell } from "views/courses/all/components/table/CourseProductCell";
import { CustomerAvatarCell } from "views/customers/all/components/table/CustomerAvatarCell";

const formatterService = new FormatterService();

/* eslint-disable react/prop-types */
export const CUSTOMER_DATA_TABLE = {
  columns: [
    { Header: "name", accessor: "name", width: "30%", Cell: ({ value: [name, image, email, createdDate] }) => <CustomerAvatarCell image={image} createdDate={createdDate} name={name} description={email} /> },
    { Header: "phone", accessor: "phone" },
    { Header: "active", accessor: "active", Cell: ({ value }) => <ActiveDisabled status={value} /> },
    { Header: "joined", accessor: "joined" },
    { Header: "action", accessor: "action" },
  ],
  rows: []
}

export const COURSE_TABLE = {
  columns: [
    { Header: "product", accessor: "product", width: "40%", Cell: ({ value: [name, image], ...rest }) => <CourseProductCell image={image} createdDate={rest.row.original.createdDate} name={name} description={rest.row.original.description} /> },
    { Header: "level", accessor: "level" },
    { Header: "price", accessor: "price", Cell: data => formatterService.formatCurrency(data.row.original.currency?.label || "CAD", data.value) },
    { Header: "status", accessor: "active", Cell: ({ value }) => <ActiveDisabled status={value} /> },
    { Header: "action", accessor: "action" },
  ],
  rows: [],
};

export const VIDEO_TABLE_COLUMNS = [
  { name: "title", align: "left" },
  { name: "description", align: "left" },
  { name: "privacy", align: "right" },
  { name: "status", align: "right" },
  { name: "joined", align: "right" },
  { name: "action", align: "right" }
];