import firebase from "firebase/app";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

import { getAuth } from "firebase/auth";
import { environment } from "configs/environment";

// Initialize Firebase

export const app = initializeApp(environment.FIREBASE, "cakesbysim");

// Initialize Firebase Authentication and get a reference to the service
export const authentication = getAuth(app);

// Initialize Cloud Firestore and get a reference to the service
export const firestore = getFirestore(app);





