import SoftBox from "components/SoftBox";
import PropTypes from "prop-types";
import SoftTypography from "components/SoftTypography";
import FormField from "components/layouts/applications/wizard/components/FormField";
import { handleFormData, handleSelectFormData } from "services/HandleService";
import { SoftDropzone } from "views/components/SoftDropzone";

export function MediaFile(props) {
  return (
    <SoftBox>
      <SoftTypography variant="h5">Media</SoftTypography>
      <SoftBox mt={3}>
        <SoftBox item xs={12}>
          <FormField type="text" value={props.formData.youtubeLink || ""} name="youtubeLink" label="youtube link" placeholder="https://..." onChange={(event) => handleFormData(event, props.setFormData)} />
        </SoftBox>
        <SoftBox mt={3} mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <SoftTypography component="label" variant="caption" fontWeight="bold">
            Select Thumbnail
          </SoftTypography>
        </SoftBox>
        <SoftDropzone file={props.formData.thumbnail} options={{
          addRemoveLinks: true,
          maxFiles: 1, // Allow only one file
          acceptedFiles: "image/*", // Accept only video files
          dictDefaultMessage: "Drop a image file here or click to upload",
          init: function () {
            this.on("addedfile", (file) => {
              // Perform actions when a file is added
              console.log("Added file: " + file);
              if (this.files.length > 1) {
                this.removeFile(this.files[0]);
              }
              handleSelectFormData("thumbnail", file, props.setFormData);
            });
          },
        }} />
      </SoftBox>
    </SoftBox>
  );
}

MediaFile.propTypes = {
  formData: PropTypes.any.isRequired,
  setFormData: PropTypes.func.isRequired
};
