import { AuthenticationService } from "services/firebase/authentication/AuthenticationService";
import { createContext } from "react"
import { CourseRepository } from "services/firebase/firestore/CourseRepository";
import { UserRepository } from "services/firebase/firestore/UserRepository";
import { ValidatorService } from "services/ValidatorService";
import { FileStorageService } from "services/http/VideoService";
import { FileStorageRepository } from "services/firebase/firestore/FileStorageRepository";
import { FormatterService } from "services/FormatterService";
import { ACCOUNT_PERMISSION } from "models/User";

export const contextData = {
  user: null,
  permission: ACCOUNT_PERMISSION,
  authenticationService: new AuthenticationService(),
  validatorService: new ValidatorService(),
  courseRepository: new CourseRepository(),
  userRepository: new UserRepository(),
  fileStorageService: new FileStorageService(),
  fileStorageRepository: new FileStorageRepository(),
  formatterService: new FormatterService()
}

const contextState = {
  context: contextData,
  setContext: () => { }
}

export const Context = createContext(contextState);