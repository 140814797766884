import { Box, Dialog, DialogActions, DialogContent } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { Context } from "contexts/contextState";
import { VIDEO_ENUM, Video } from "models/Video";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { VideoPlayer } from "views/components/VideoPlayer";

export function VideoPreviewModal(props) {
  const { context } = useContext(Context);
  const [videoLink, setVideoLink] = useState(props.video.youtubeLink || "");

  async function handlePageLoader() {
    const video = new Video(props.video);
    if (video.privacy == VIDEO_ENUM.PRIVATE) {
      const videoResponse = await context.fileStorageRepository.findFileStorageById(video.videoReference?.id);
      setVideoLink(videoResponse.fileUrl);
    }
  }

  useEffect(() => {
    handlePageLoader();
  }, []);

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <SoftBox mx="16px" mt="16px">
        <SoftTypography variant="h5">Video Lesson: {props.video.title}</SoftTypography>
        <SoftTypography variant="button" fontWeight="regular" color="text">
          If the video is not yet visible, it could be due to ongoing processing on the servers end.
        </SoftTypography>
      </SoftBox>
      <DialogContent>
        <SoftBox display="flex" flex="1" flexDirection="column" mt={1}>
          <VideoPlayer videoUrl={videoLink} />
        </SoftBox>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="space-between" flex="1">
          <SoftButton variant="text" color="info" onClick={props.onClose}>Close</SoftButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

VideoPreviewModal.propTypes = {
  video: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}