import { useContext, useEffect, useState } from "react";
import { useLocation, NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SidenavCollapse from "components/examples/Sidenav/SidenavCollapse";
import SidenavList from "components/examples/Sidenav/SidenavList";
import SidenavItem from "components/examples/Sidenav/SidenavItem";
import SidenavCard from "components/examples/Sidenav/SidenavCard";
import SidenavRoot from "components/examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "components/examples/Sidenav/styles/sidenav";
import { useSoftUIController, setMiniSidenav } from "components/layouts/context";
import { Context } from "contexts/contextState";

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const { context } = useContext(Context);
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentSidenav } = controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[1];
  const itemName = pathname.split("/").slice(1)[2];
  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse) => {
    const template = collapse.map(({ name, route, key, href }) => href ? (
      <Link key={key} href={href} target="_blank" rel="noreferrer" sx={{ textDecoration: "none" }} >
        <SidenavItem name={name} nested />
      </Link>
    ) : (
      <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
        <SidenavItem name={name} active={route === pathname} nested />
      </NavLink>
    )
    );

    return template;
  };

  // Render the all the collpases from the routes.js
  const renderCollapse = (collapses) => collapses.filter(collapse => !collapse.hidden).map(({ name, collapse, route, href, key }) => {
    let returnValue;
    if (collapse) {
      returnValue = (
        <SidenavItem key={key} name={name} active={key === itemName} open={openNestedCollapse === name} onClick={() => openNestedCollapse === name ? setOpenNestedCollapse(false) : setOpenNestedCollapse(name)}>
          {renderNestedCollapse(collapse)}
        </SidenavItem>
      );
    } else {
      returnValue = href ? (
        <Link href={href} key={key} target="_blank" rel="noreferrer" sx={{ textDecoration: "none" }} >
          <SidenavItem name={name} active={key === itemName} />
        </Link>
      ) : (
        <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
          <SidenavItem name={name} active={key === itemName} />
        </NavLink>
      );
    }
    return <SidenavList key={key}>{returnValue}</SidenavList>;
  });

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.filter(router => !router.hidden).map(({ type, name, icon, title, collapse, noCollapse, key, href, route }) => {

    if (type === "collapse") {
      if (!context.permission.root && !context.permission[key.slice(0, -1)]?.view) {
        return null
      }
      if (href) {
        return (
          <Link href={href} key={key} target="_blank" rel="noreferrer" sx={{ textDecoration: "none" }} >
            <SidenavCollapse name={name} icon={icon} active={key === collapseName} noCollapse={noCollapse} />
          </Link>
        );
      } else if (noCollapse && route) {
        return (
          <NavLink to={route} key={key}>
            <SidenavCollapse name={name} icon={icon} noCollapse={noCollapse} active={key === collapseName} >
              {collapse ? renderCollapse(collapse) : null}
            </SidenavCollapse>
          </NavLink>
        );
      } else {
        return (
          <SidenavCollapse key={key} name={name} icon={icon} active={key === collapseName} open={openCollapse === key} onClick={() => (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key))} >
            {collapse ? renderCollapse(collapse) : null}
          </SidenavCollapse>
        );
      }
    } else if (type === "title") {
      return (
        <SoftTypography key={key} display="block" variant="caption" fontWeight="bold" textTransform="uppercase" opacity={0.6} pl={3} mt={2} mb={1} ml={1}>
          {title}
        </SoftTypography>
      );
    } else if (type === "divider") {
      return <Divider key={key} />;
    }
    return null;
  }
  );

  return (
    <SidenavRoot {...rest} variant="permanent" ownerState={{ transparentSidenav, miniSidenav }}>
      <SoftBox pt={3} pb={1} px={4} textAlign="center">
        <SoftBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <SoftTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </SoftTypography>
        </SoftBox>
        <SoftBox component={NavLink} to="/" display="flex" alignItems="center">
          {brand && <SoftBox component="img" src={brand} alt="Soft UI Logo" width="2rem" />}
          <SoftBox
            width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <SoftTypography component="h6" variant="button" fontWeight="medium" sx={{ marginTop: "10px", marginLeft: "5px" }}>
              {brandName}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      </SoftBox>
      <Divider />
      <List>{renderRoutes}</List>

      <SoftBox pt={2} my={2} mx={2}>
        <SidenavCard />
      </SoftBox>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
