import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";
import { useState } from "react";
import { DeleteVideoModal } from "../DeleteVideoModal";
import { EditVideoModal } from "../EditVideoModal";
import { VideoPreviewModal } from "../VideoPreviewModal";

export function VideoActionCell(props) {
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  return (
    <SoftBox display="flex" alignItems="center">
      <VideoPreviewModal open={openPreviewModal} video={props.video} onClose={() => setOpenPreviewModal(false)} />
      <EditVideoModal open={openEditModal} course={props.course} video={props.video} onClose={() => setOpenEditModal(false)} />
      <DeleteVideoModal open={openDeleteModal} course={props.course} video={props.video} onClose={() => setOpenDeleteModal(false)} />
      <SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} onClick={() => setOpenPreviewModal(true)}>
        <Tooltip title="Preview Video" placement="top">
          <Icon>visibility</Icon>
        </Tooltip>
      </SoftTypography>
      <SoftBox mx={2}>
        <SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} onClick={() => setOpenEditModal(true)}>
          <Tooltip title="Edit Video" placement="top">
            <Icon>edit</Icon>
          </Tooltip>
        </SoftTypography>
      </SoftBox>
      <SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} onClick={() => setOpenDeleteModal(true)}>
        <Tooltip title="Delete Video" placement="left">
          <Icon>delete</Icon>
        </Tooltip>
      </SoftTypography>
    </SoftBox>
  );
}

VideoActionCell.propTypes = {
  course: PropTypes.any.isRequired,
  video: PropTypes.any.isRequired,
};