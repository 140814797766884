import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Switch from "@mui/material/Switch";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import IllustrationLayout from "components/layouts/authentication/components/IllustrationLayout";
import hero from "assets/images/hero.png";
import { Context } from "contexts/contextState";
import { handleFormData } from "services/HandleService";
import { errorAlert, successAlert } from "./components/Alerts";

export function LoginView(props) {
  const navigate = useNavigate();
  const { context } = useContext(Context);
  const [rememberMe, setRememberMe] = useState(false);
  const [loginAndPassword, setLoginAndPassword] = useState({ email: "", password: "" });

  async function handleLoginWithEmailAndPassword(event) {
    const authenticationResponse = await context.authenticationService.signIn(loginAndPassword.email, loginAndPassword.password);
    if (authenticationResponse?.user) {
      const accountResponse = await context.userRepository.findUserById(authenticationResponse.user.uid);
      if (accountResponse.permission?.root || accountResponse.permission?.admin) {
        navigate("/admin/customers/all");
        successAlert("Success!", "You have been logged in successfully.");
      } else {
        context.authenticationService.signOut();
        errorAlert("Error!", "Failed to log in. Insufficient admin privileges.");
      }
    }
  }

  return (
    <IllustrationLayout
      title="Cakes By Sim"
      color="white"
      illustration={{ image: hero }}
    >
      <SoftTypography variant="h5" mt={1} mb={1}>Sign In</SoftTypography>
      <SoftTypography variant="body2" mb={2}>Enter your email and password to sign in</SoftTypography>
      <SoftBox component="form" role="form">
        <SoftBox mb={2}>
          <SoftInput type="email" placeholder="Email" size="large" name="email" onChange={(event) => handleFormData(event, setLoginAndPassword)} />
        </SoftBox>
        <SoftBox mb={2}>
          <SoftInput type="password" placeholder="Password" size="large" name="password" onChange={(event) => handleFormData(event, setLoginAndPassword)} />
        </SoftBox>
        <SoftBox display="flex" alignItems="center">
          <Switch checked={rememberMe} onChange={() => setRememberMe(!rememberMe)} />
          <SoftTypography
            variant="button"
            fontWeight="regular"
            onClick={() => setRememberMe(!rememberMe)}
            sx={{ cursor: "pointer", userSelect: "none" }}
          >
            &nbsp;&nbsp;Remember me
          </SoftTypography>
        </SoftBox>
        <SoftBox mt={4} mb={1}>
          <SoftButton fullWidth variant="gradient" color="info" size="large" onClick={handleLoginWithEmailAndPassword}>
            sign in
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </IllustrationLayout>
  );
}
