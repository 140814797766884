import { AppBar, Card, Grid, Stack, Tab, Tabs } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import Footer from "components/examples/Footer";
import DashboardLayout from "components/examples/LayoutContainers/DashboardLayout";
import { useContext, useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom";
import { MainHeader } from "views/components/MainHeader";
import { CustomerHeader } from "./components/CustomerHeader";
import { Context } from "contexts/contextState";
import { EditCustomerProfile } from "./fragments/EditCustomerProfile";
import { EditCustomerPermission } from "./fragments/EditCustomerPermission";
import { EditCustomerSetting } from "./fragments/EditCustomerSetting";

// use this component to create new pages view
export function EditCustomerView(props) {
  /****************** hooks ********************/
  const params = useParams();
  const { context } = useContext(Context);
  const [firstPageLoader, setFirstPageLoader] = useState(true);
  const [customer, setCustomer] = useState({});
  const [tabNumber, setTabNumber] = useState(0);

  /****************** functions ********************/

  async function handlePageLoader() {
    const customerResponse = await context.userRepository.findUserById(params.id);
    setCustomer(customerResponse);
  }

  /****************** useEffects ********************/

  useEffect(() => {
    // it will just execute once
    if (firstPageLoader) {
      setFirstPageLoader(false);
      // codding ...
      handlePageLoader();
    }
  }, []);

  return (
    <DashboardLayout>
      <MainHeader />
      <SoftBox my={3}>
        <Card sx={{ overflow: "visible" }}>
          <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>
            <SoftBox lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                Edit Customer
              </SoftTypography>
              <SoftTypography variant="button" fontWeight="regular" color="text">
                Data Refinement for Customer Information
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          <SoftBox mb="20px">
            <CustomerHeader customer={customer} onChange={(tabNumber) => setTabNumber(tabNumber)} />
          </SoftBox>
          {tabNumber == 0 && <EditCustomerProfile customer={customer} setCustomer={setCustomer} />}
          {tabNumber == 1 && <EditCustomerPermission customer={customer} setCustomer={setCustomer} />}
          {tabNumber == 2 && <EditCustomerSetting customer={customer} setCustomer={setCustomer} />}
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  )
}