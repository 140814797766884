
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import FormField from "components/layouts/ecommerce/products/new-product/components/FormField";
import { handleFormData, handleSelectFormData } from "services/HandleService";

export function ProductInfo(props) {
  return (
    <SoftBox>
      <SoftTypography variant="h5">Product Info</SoftTypography>
      <SoftBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField type="text" label="title" name="title" value={props.formData.title || ""} placeholder="eg. Oreo Overloaded" onChange={(event) => handleFormData(event, props.setFormData)} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftBox mb={3}>
              <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography component="label" variant="caption" fontWeight="bold" textTransform="capitalize">
                  Status
                </SoftTypography>
              </SoftBox>
              <SoftSelect defaultValue={props.formData.active != void 0 ? COURSE_STATUS[props.formData.active ? 0 : 1] : COURSE_STATUS[1]} options={COURSE_STATUS} onChange={(obj) => handleSelectFormData("active", obj.value, props.setFormData)} />
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
      <SoftBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography component="label" variant="caption" fontWeight="bold">Description</SoftTypography>
              <SoftTypography variant="caption" fontWeight="regular" color="text">(optional)</SoftTypography>
            </SoftBox>
            <FormField type="text" name="description" value={props.formData.description || ""} onChange={(event) => handleFormData(event, props.setFormData)} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftBox mb={3}>
              <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography component="label" variant="caption" fontWeight="bold" textTransform="capitalize">
                  Skill Level
                </SoftTypography>
              </SoftBox>
              <SoftSelect defaultValue={props.formData.courseLevel || ""} options={SKILL_LEVEL} onChange={(obj) => handleSelectFormData("courseLevel", obj, props.setFormData)} />
            </SoftBox>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography component="label" variant="caption" fontWeight="bold" textTransform="capitalize">
                Size
              </SoftTypography>
            </SoftBox>
            <SoftSelect defaultValue={props.formData.courseSize || ""} options={COURSE_SIZE} onChange={(obj) => handleSelectFormData("courseSize", obj, props.setFormData)} />
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

ProductInfo.propTypes = {
  formData: PropTypes.any.isRequired,
  setFormData: PropTypes.func.isRequired
};

export const COURSE_STATUS = [
  { value: true, label: "Active" },
  { value: false, label: "Disabled" },
];

export const SKILL_LEVEL = [
  { value: "beginner", label: "Beginner" },
  { value: "intermediate", label: "Intermediate" },
  { value: "advanced", label: "Advanced" }
];

export const COURSE_SIZE = [
  { value: "small", label: "Small" },
  { value: "medium", label: "Medium" },
  { value: "large", label: "Large" },
  { value: "extra small", label: "Extra Small" },
  { value: "extra large", label: "Extra Large" },
]
