import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftSelect from "components/SoftSelect";
import SoftTypography from "components/SoftTypography";
import FormField from "components/layouts/applications/wizard/components/FormField";
import { environment } from "configs/environment";
import { Context } from "contexts/contextState";
import { doc } from "firebase/firestore";
import { Course } from "models/Course";
import { VIDEO_ENUM, VIDEO_PRIVACY, VIDEO_STATUS, Video } from "models/Video";
import PropTypes from "prop-types";
import { useContext, useState } from "react";
import { firestore } from "services/firebase/configs/firebase";
import { errorAlert, successAlert } from "views/components/Alerts";
import { ProgressBarModal } from "views/components/ProgressBarModal";
import { SoftDropzone } from "views/components/SoftDropzone";
import { handleFormData, handleSelectFormData } from "services/HandleService";

export function CreateVideoLessonModal(props) {
  const { context } = useContext(Context);
  const [formVideo, setFormVideo] = useState({});
  const [processing, setProcessing] = useState(false);
  const [percent, setPercent] = useState(0);

  async function handleSaveVideoLesson() {
    setProcessing(true);
    if (!!formVideo.youtubeLink || (formVideo.privacy == VIDEO_ENUM.PRIVATE && formVideo.file)) {
      const course = new Course(props.course);
      const video = new Video(formVideo);
      try {
        if (video.privacy == VIDEO_ENUM.PRIVATE && formVideo.file) {
          // delete the last video from storage
          if (!!course.videos.length) {
            const lastVideo = course.videos[0];
            if (lastVideo.privacy == VIDEO_ENUM.PRIVATE) {
              await context.fileStorageRepository.delete(lastVideo.videoReference.id);
            }
          }
          const responseVideoStorage = await context.fileStorageService.videoUpload(formVideo.file, (event) => {
            setPercent(Math.floor(event.loaded / event.total * 100));
          });
          video.videoReference = doc(firestore, environment.COLLECTION.FILE_STORAGES, responseVideoStorage.id);
        }
        video.createdDate = new Date();
        course.videos = [video];
        const courseResponse = await context.courseRepository.save(course);
        console.log("handleSaveVideoLesson", courseResponse);
        successAlert("Success!", "The video lesson has been successfully created.").then(() => {
          window.location.reload();
        });
        setFormVideo({});
        props.onClose();
      } catch (error) {
        console.log(error);
        errorAlert("Error!", "Failed to create a video in our database. Please check your connection and try again. If the issue persists, please contact our support team for assistance.");
      } finally {
        setProcessing(false);
      }
    }
  }

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <ProgressBarModal open={processing} percent={percent} onClose={props.onClose} />
      <SoftBox mx="16px" mt="16px">
        <SoftTypography variant="h5">Video Information</SoftTypography>
        <SoftTypography variant="button" fontWeight="regular" color="text">
          If you confirm the creation, the last video lesson will be deleted.
        </SoftTypography>
      </SoftBox>
      <DialogContent>
        <SoftBox display="flex" flex="1" flexDirection="column" mt={1} minWidth="480px">
          <FormField type="text" label="title" name="title" value={formVideo.title || ""} placeholder="eg. Oreo Overloaded" onChange={(event) => handleFormData(event, setFormVideo)} />
          <SoftBox mt={1}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography component="label" variant="caption" fontWeight="bold" textTransform="capitalize">
                Status
              </SoftTypography>
            </SoftBox>
            <SoftSelect value={formVideo.active != void 0 ? VIDEO_STATUS[formVideo.active ? 0 : 1] : VIDEO_STATUS[1]} options={VIDEO_STATUS} onChange={(obj) => handleSelectFormData("active", obj.value, setFormVideo)} />
          </SoftBox>
          <SoftBox mt={1}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography component="label" variant="caption" fontWeight="bold" textTransform="capitalize">
                Privacy
              </SoftTypography>
            </SoftBox>
            <SoftSelect value={VIDEO_PRIVACY.find(privacy => privacy.value == formVideo.privacy) || VIDEO_PRIVACY[0]} options={VIDEO_PRIVACY} onChange={(obj) => handleSelectFormData("privacy", obj.value, setFormVideo)} />
          </SoftBox>
          <SoftBox mt={1}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography component="label" variant="caption" fontWeight="bold">Description</SoftTypography>
              <SoftTypography variant="caption" fontWeight="regular" color="text">(optional)</SoftTypography>
            </SoftBox>
            <FormField type="text" name="description" value={formVideo.description || ""} onChange={(event) => handleFormData(event, setFormVideo)} />
          </SoftBox>
          <SoftBox mt={1} display={formVideo.privacy == VIDEO_ENUM.PRIVATE ? "none" : "block"}>
            <FormField type="text" value={formVideo.youtubeLink || ""} name="youtubeLink" label="youtube link" placeholder="https://..." onChange={(event) => handleFormData(event, setFormVideo)} />
          </SoftBox>
          <SoftBox mt={1} display={formVideo.privacy == VIDEO_ENUM.PRIVATE ? "block" : "none"}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography component="label" variant="caption" fontWeight="bold" textTransform="capitalize">
                Select Video
              </SoftTypography>
            </SoftBox>
            <SoftDropzone file={formVideo.file} options={{
              addRemoveLinks: true,
              maxFiles: 1, // Allow only one file
              acceptedFiles: "video/*", // Accept only video files
              dictDefaultMessage: "Drop a video file here or click to upload",
              init: function () {
                this.on("addedfile", (file) => {
                  // Perform actions when a file is added
                  console.log("Added file: " + file);
                  if (this.files.length > 1) {
                    this.removeFile(this.files[0]);
                  }
                  handleSelectFormData("file", file, setFormVideo);
                });
                this.on("removedfile", (file) => {
                  handleSelectFormData("file", null, setFormVideo);
                });
              },
            }}
            />
          </SoftBox>
        </SoftBox>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="space-between" flex="1">
          <SoftButton variant="text" color="info" onClick={props.onClose} disabled={processing}>Cancel</SoftButton>
          <SoftButton variant="text" color="info"
            onClick={handleSaveVideoLesson}
            disabled={!(context.permission?.root || context.permission?.course?.update)}
          >
            Confirm
          </SoftButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

CreateVideoLessonModal.propTypes = {
  course: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}