import Card from "@mui/material/Card";
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Table from "components/examples/Tables/Table";
import { Context } from "contexts/contextState";
import { useContext, useEffect, useState } from "react";
import { VIDEO_TABLE_COLUMNS } from "views/constants/tables";
import { VideoActionCell } from "../components/table/VideoActionCell";
import SoftButton from "components/SoftButton";
import PropTypes from "prop-types";
import { CreateVideoLessonModal } from "../components/CreateVideoLessonModal";

export function VideosLesson(props) {
  const { context } = useContext(Context);
  const [videos, setVideos] = useState([]);
  const [showUploadLessonModal, setShowUploadLessonModal] = useState();

  function handlePageLoader() {
    setVideos(props.course.videos?.map(video => ({
      title: ["", <SoftTypography variant="p" color="inherit">{video.title}</SoftTypography>],
      description: context.formatterService.formatStringShortText(video.description),
      privacy: video.privacy,
      status: video.active ? <SoftBadge variant="contained" color="success" size="xs" badgeContent="Active" container /> : <SoftBadge variant="contained" color="error" size="xs" badgeContent="Disabled" container />,
      joined: context.formatterService.formatDate(video.createdDate?.toDate()),
      action: <VideoActionCell course={props.course} video={video} />
    })))
  }

  useEffect(() => {
    handlePageLoader();
  }, [props.course]);

  return (
    <Card>
      <CreateVideoLessonModal open={Boolean(showUploadLessonModal)} course={props.course} onClose={() => setShowUploadLessonModal(void 0)} />
      <SoftBox p={3}>
        <SoftBox display="flex" justifyContent="space-between">
          <SoftTypography variant="h5" fontWeight="bold">
            Video Lessons
          </SoftTypography>
          <SoftButton variant="outlined" color="info" size="small"
            onClick={() => setShowUploadLessonModal(true)}
            disabled={!(context.permission?.root || context.permission?.course?.update)}
          >
            Upload Lesson
          </SoftButton>
        </SoftBox>
        <SoftBox mt={1}>
          <Table columns={VIDEO_TABLE_COLUMNS} rows={videos} />
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

VideosLesson.propTypes = {
  course: PropTypes.object.isRequired,
  setCourse: PropTypes.func.isRequired
}